import React, {useState,useEffect} from "react";
import {Alert, Snackbar} from "@mui/material";

const ErrorMsg = ({message,openn,seterrormsg})=>{
    const [open , setOpen] = useState(true);
    useEffect(()=>{
        setOpen(openn)
    },open)
    const handlemsgclose = ()=>{
        setOpen(true);
        seterrormsg("");
    }
    return(
        <div>
            <Snackbar
                open={open}
                autoHideDuration={2500}
                onClose={handlemsgclose}
                anchorOrigin={{ vertical:"bottom", horizontal:"center" }}
            >
                <Alert severity="error" variant="filled" sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>
        </div>

    )

}
export default ErrorMsg