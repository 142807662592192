import logo from './logo.svg';
import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Dashboard from "./Dashboard";
import Login from "./Login";
import AddNewProduct from "./AddNewProduct";
import ManageProduct from "./ManageProduct";
import AddNewCustomer from "./AddNewCustomer";
import ManageCustomer from "./ManageCustomer";
import CreateFactor from "./CreateFactor";
import Report from "./Report";
import CreateUser from "./CreateUser";
import ManageUser from "./ManageUser";
function App() {
  return (
      <BrowserRouter>
        <Routes>
          <Route>
            <Route path="/" element={<Dashboard/>} />
            <Route path="/addProduct" element={<AddNewProduct/>} />
            <Route path="/mngproduct" element={<ManageProduct/>} />
            <Route path="/login" element={<Login/>} />
            <Route path="/addcustomer" element={<AddNewCustomer/>} />
            <Route path="/mngcustomer" element={<ManageCustomer/>} />
            <Route path="/createfactor" element={<CreateFactor/>} />
            <Route path="/report" element={<Report/>} />
            <Route path="/adduser" element={<CreateUser/>} />
            <Route path="/mnguser" element={<ManageUser/>} />
            {/*<Route path="*" element={<Page404/>} />*/}
          </Route>
        </Routes>
      </BrowserRouter>
  );
}

export default App;
