import React,{useState,useEffect} from "react";
import axios from "axios";
import ErrorMsg from "./msg/ErrorMsg";
import SuccsMsg from "./msg/SuccsMsg";
import {Navigate} from "react-router-dom";
import {
    Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton,
    styled, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, TextField, Typography,
} from "@mui/material";
import Appbar from "./Appbar";
import MenuList from "./MenuList";
import BorderColorIcon from '@mui/icons-material/BorderColor';
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));
const ManageProduct=()=>{
    const [loading , setLoading] = useState(true);
    const [productList , setProductList] = useState([]);
    const [errorMsg, setErrorMsg] = useState("");
    const [succsMsg, setSuccsMsg] = useState("");
    const [dialogEdit , setDialogEdit] = useState(false);
    const [selectStock , setSelectStock] = useState("");
    const [selectStockinway , setSelectStockinway] = useState("");
    const [selectId , setSelectId] = useState("");
    const [update , setUpdate] = useState(false);
    const [selectName , setSelectName] = useState("");
    const [search , setSearch] = useState("");

    useEffect(() => {
        const data = {
            token : localStorage.getItem("adminlogintoken"),
            search : search
        }
        axios.post("https://o.tinpod.ir/api/get_productlist.php",data)
            .then(response => {
                setLoading(false);
                if (response.status === 200) {
                    if (response.data.error === false)
                        setProductList(response.data.data)
                    else{
                        localStorage.clear();
                    }
                }
            })
            .catch(error => {
                setLoading(false);
            })
        setUpdate(false)
    }, [update,search])
    const Redirect = () => {
        return (<Navigate replace to="/login"/>)
    }
    const handle_btn_edit=()=>{

        setLoading(true);
        let newstock = String(selectStock).replace(/,/g, '');
        let newstockInway = String(selectStockinway).replace(/,/g, '');
        const data = {
            token : localStorage.getItem("adminlogintoken"),
            productid : selectId,
            stock : newstock,
            stockinway : newstockInway,
        }
        axios.post("https://o.tinpod.ir/api/updatestock.php",data)
            .then(response => {
                setLoading(false);
                if (response.status === 200) {
                    if (response.data.error === false){
                        setUpdate(true);
                        setDialogEdit(false);
                        setSuccsMsg(response.data.message);
                    }
                    else{
                        setErrorMsg(response.data.message);
                    }
                }
            })
            .catch(error => {
                setLoading(false);
            })
    }
    function toEnglishDigits(str) {
        // convert persian digits [۰۱۲۳۴۵۶۷۸۹]
        var e = '۰'.charCodeAt(0);
        str = str.replace(/[۰-۹]/g, function(t) {
            return t.charCodeAt(0) - e;
        });

        // convert arabic indic digits [٠١٢٣٤٥٦٧٨٩]
        e = '٠'.charCodeAt(0);
        str = str.replace(/[٠-٩]/g, function(t) {
            return t.charCodeAt(0) - e;
        });
        return str;
    }
    function number_format(number){
        let pe=toEnglishDigits(number);
        pe = String(pe).replace(/,/g, '');
        let nf = new Intl.NumberFormat("en-US");
        if (nf.format(pe) !== "NaN")
            return (nf.format(pe));
    }
    return(
        <div>
            {localStorage.getItem("adminlogintoken") === "" ? <Redirect/> : ''}
            {localStorage.getItem("adminlogintoken") === null ? <Redirect/> : ''}
            {errorMsg !== '' ? <ErrorMsg message={errorMsg} openn={true} seterrormsg={setErrorMsg}/> : ''}
            {succsMsg !== '' ? <SuccsMsg message={succsMsg} openn={true} setsuccsmsg={setSuccsMsg}/> : ''}
            <Grid container spacing={2} sx={{marginTop: 0}}>
                <Grid item xs={12} md={10} sx={{backgroundColor: "#ffffff", minHeight: "100vh"}}>
                    <Appbar/>
                    <Box sx={{padding:2}} dir={"rtl"}>
                        <TextField
                            color={"info"}
                            autoComplete="off"
                            fullWidth
                            onChange={(e) => setSearch(e.target.value)}
                            placeholder={"جست و جو بر اساس نام کالا"}
                            size={"small"}
                            sx={{marginBottom:2}}
                            variant="outlined"/>
                        <TableContainer >
                            <Table
                                sx={{ minWidth: 250,maxHeight:'80%',overflowY:'auto'}}>
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell align={"right"}>
                                            {"کد کالا"}
                                        </StyledTableCell>
                                        <StyledTableCell align={"right"}>
                                            {"نام کالا"}
                                        </StyledTableCell>
                                        <StyledTableCell align={"right"}>
                                            {"موجودی (تعداد)"}
                                        </StyledTableCell>
                                        <StyledTableCell align={"right"}>
                                            {"موجودی در راه (تعداد)"}
                                        </StyledTableCell>
                                        <StyledTableCell align={"left"}>
                                            {"عملیات"}
                                        </StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                {productList !== "" ?
                                    <TableBody>
                                        {productList.map(list => (
                                            <StyledTableRow key={list.id}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                <StyledTableCell align={"right"}>
                                                    {list.id}
                                                </StyledTableCell>
                                                <StyledTableCell align={"right"}>
                                                    {list.name}
                                                </StyledTableCell>
                                                <StyledTableCell
                                                    sx={{backgroundColor: list.stock < 0 ? "#fc9494" : ""}}
                                                    component="th" scope="row" align={"right"}>
                                                    <div dir={"ltr"}>
                                                        {list.stock.toLocaleString()}
                                                    </div>
                                                </StyledTableCell>
                                                <StyledTableCell
                                                    sx={{backgroundColor: list.stockinway < 0 ? "#fc9494" : ""}}
                                                    component="th" scope="row" align={"right"}>
                                                    <div dir={"ltr"}>
                                                        {list.stockinway.toLocaleString()}
                                                    </div>
                                                </StyledTableCell>
                                                <StyledTableCell component="th" scope="row" align={"left"}>
                                                    <IconButton
                                                        onClick={()=>{
                                                            setSelectStock(list.stock);
                                                            setSelectStockinway(list.stockinway);
                                                            setDialogEdit(true);
                                                            setSelectId(list.id);
                                                            setSelectName(list.name);
                                                        }}
                                                        aria-label="delete">
                                                        <BorderColorIcon sx={{color:"green"}}  />
                                                    </IconButton>
                                                </StyledTableCell>
                                            </StyledTableRow >
                                        ))}
                                    </TableBody>
                                    : '' }
                            </Table>
                        </TableContainer>
                    </Box>
                </Grid>
                <Grid item xs={0} md={2} sx={{
                    backgroundColor: '#2b2b2b',
                    minHeight: "100vh",
                    display: {xs: "none", md: "block"}
                }}>
                    <MenuList/>
                </Grid>
            </Grid>
            <Dialog
                open={dialogEdit}
                onClose={()=>setDialogEdit(false)}>
                <DialogTitle dir={"rtl"}>
                    {"ویرایش موجودی"}
                </DialogTitle>
                <DialogContent>
                    <div>
                        <div style={{textAlign:"center"}} dir={"rtl"}>
                            {selectId} - {selectName}
                        </div>
                        <div style={{textAlign:"right"}}>موجودی</div>
                        <TextField
                            color={"info"}
                            autoComplete="off"
                            fullWidth
                            value={selectStock}
                            onChange={(e)=>setSelectStock(number_format(e.target.value))}
                            size={"small"}
                            sx={{
                                marginTop: 1,
                                marginBottom: 1,
                            }}
                            variant="outlined"/>
                        <div style={{textAlign:"right"}}>موجودی در راه</div>
                        <TextField
                            color={"info"}
                            autoComplete="off"
                            fullWidth
                            value={selectStockinway}
                            onChange={(e)=>setSelectStockinway(number_format(e.target.value))}
                            size={"small"}
                            sx={{
                                marginTop: 1,
                                marginBottom: 1,
                            }}
                            variant="outlined"/>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>setDialogEdit(false)}>بستن</Button>
                    <Button onClick={handle_btn_edit} autoFocus>
                        ثبت
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={loading}
                scroll="paper">
                <CircularProgress sx={{margin: 2}}/>
            </Dialog>
        </div>
    )
}
export default ManageProduct;