import React, {useEffect, useState} from "react"
import {Navigate} from "react-router-dom";
import Appbar from "./Appbar";
import MenuList from "./MenuList";
import {
    Box, CircularProgress, Dialog, Grid, styled, Table, TableBody, TableCell, tableCellClasses,
    TableContainer, TableHead, TableRow, TextField
} from "@mui/material";
import axios from "axios";
import ErrorMsg from "./msg/ErrorMsg";
import SuccsMsg from "./msg/SuccsMsg";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));
const Dashboard = () => {
    const [loading , setLoading] = useState(true);
    const [productList , setProductList] = useState([]);
    const [errorMsg, setErrorMsg] = useState("");
    const [succsMsg, setSuccsMsg] = useState("");
    const [search , setSearch] = useState("");
    useEffect(() => {
        const data = {
            token : localStorage.getItem("adminlogintoken"),
            search : search,
            limitzero : true,
        }
        axios.post("https://o.tinpod.ir/api/get_productlist.php",data)
            .then(response => {
                console.log(response.data);
                setLoading(false);
                if (response.status === 200) {
                    if (response.data.error === false)
                        setProductList(response.data.data)
                    else{
                        localStorage.clear();
                    }
                }
            })
            .catch(error => {
                setLoading(false);
            })
    }, [search])
    const Redirect = () => {
        return (<Navigate replace to="/login"/>)
    }
    function toEnglishDigits(str) {
        // convert persian digits [۰۱۲۳۴۵۶۷۸۹]
        var e = '۰'.charCodeAt(0);
        str = str.replace(/[۰-۹]/g, function(t) {
            return t.charCodeAt(0) - e;
        });

        // convert arabic indic digits [٠١٢٣٤٥٦٧٨٩]
        e = '٠'.charCodeAt(0);
        str = str.replace(/[٠-٩]/g, function(t) {
            return t.charCodeAt(0) - e;
        });
        return str;
    }
    function number_format(number){
        let pe=toEnglishDigits(number);
        pe = String(pe).replace(/,/g, '');
        let nf = new Intl.NumberFormat("en-US");
        if (nf.format(pe) !== "NaN")
            return (nf.format(pe));
    }
    return (
        <div>
            {localStorage.getItem("adminlogintoken") === "" ? <Redirect/> : ''}
            {localStorage.getItem("adminlogintoken") === null ? <Redirect/> : ''}
            {errorMsg !== '' ? <ErrorMsg message={errorMsg} openn={true} seterrormsg={setErrorMsg}/> : ''}
            {succsMsg !== '' ? <SuccsMsg message={succsMsg} openn={true} setsuccsmsg={setSuccsMsg}/> : ''}
            <Grid container spacing={2} sx={{marginTop: 0}}>
                <Grid item xs={12} md={10} sx={{backgroundColor: "#ffffff", minHeight: "100vh"}}>
                    <Appbar/>
                    <Box sx={{padding:2}} dir={"rtl"}>
                        <TextField
                            color={"info"}
                            autoComplete="off"
                            fullWidth
                            onChange={(e) => setSearch(e.target.value)}
                            placeholder={"جست و جو بر اساس نام کالا"}
                            size={"small"}
                            sx={{marginBottom:2}}
                            variant="outlined"/>
                        <TableContainer >
                            <Table
                                sx={{ minWidth: 250,maxHeight:'80%',overflowY:'auto'}}>
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell align={"right"}>
                                            {"نام کالا"}
                                        </StyledTableCell>
                                        <StyledTableCell align={"right"}>
                                            {"موجودی (تعداد)"}
                                        </StyledTableCell>
                                        <StyledTableCell align={"right"}>
                                            {"موجودی در راه (تعداد)"}
                                        </StyledTableCell>
                                        <StyledTableCell align={"right"}>
                                            {"فروش کل"}
                                        </StyledTableCell>
                                        <StyledTableCell align={"right"}>
                                            {"مانده"}
                                        </StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                {productList !== "" ?
                                    <TableBody>
                                        {productList.map(list => (
                                            <StyledTableRow key={list.id}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                <StyledTableCell align={"right"}>
                                                    {list.name}
                                                </StyledTableCell >
                                                <StyledTableCell
                                                    sx={{backgroundColor:list.stock < 0 ? "#f8a5a5" : '',direction:"ltr"}}
                                                    component="th"
                                                    scope="row"
                                                    align={"right"}>
                                                    {list.stock.toLocaleString()}
                                                </StyledTableCell>
                                                <StyledTableCell
                                                    sx={{backgroundColor:list.stockinway < 0 ? "#f8a5a5" : '',direction:"ltr"}}
                                                    component="th" scope="row" align={"right"}>
                                                    {list.stockinway.toLocaleString()}
                                                </StyledTableCell>
                                                <StyledTableCell component="th" scope="row" align={"right"}>
                                                    {list.totalsales.toLocaleString()}
                                                </StyledTableCell>
                                                <StyledTableCell
                                                    sx={{backgroundColor:(list.remained) < 0 ? "#f8a5a5" : '',direction:"ltr"}}
                                                    component="th" scope="row" align={"right"}>
                                                    {list.remained.toLocaleString()}
                                                </StyledTableCell>
                                            </StyledTableRow >
                                        ))}
                                    </TableBody>
                                    : '' }
                            </Table>
                        </TableContainer>
                    </Box>
                </Grid>
                <Grid item xs={0} md={2} sx={{
                    backgroundColor: '#2b2b2b',
                    minHeight: "100vh",
                    display: {xs: "none", md: "block"}
                }}>
                    <MenuList/>
                </Grid>
            </Grid>
            <Dialog
                open={loading}
                scroll="paper">
                <CircularProgress sx={{margin: 2}}/>
            </Dialog>
        </div>
)
}
export default Dashboard;