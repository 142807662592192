import React, {useEffect, useState} from "react";
import {List, ListItemButton, ListItemIcon, ListItemText, Stack,
} from "@mui/material";
import {Link} from "react-router-dom";
import axios from "axios";
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import CategoryIcon from '@mui/icons-material/Category';
import font from "./fonts/font.css"
import DashboardIcon from '@mui/icons-material/Dashboard';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import ListAltIcon from '@mui/icons-material/ListAlt';
import PostAddIcon from '@mui/icons-material/PostAdd';
import AssessmentIcon from '@mui/icons-material/Assessment';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
const MenuList = () => {
    const [role, setRole] = useState(null);
    useEffect(() => {
        const data={
            token : localStorage.getItem("adminlogintoken"),
        }
        axios.post("https://o.tinpod.ir/api/userinfo.php",data)
            .then(response => {
                if (response.status === 200) {
                    if (response.data.error === false) {
                        setRole(response.data.role);
                    }
                    else {
                        localStorage.clear();
                        window.location.href = "/login"
                    }
                }
            })
            .catch(error => {
            })
    }, [])
    return (
        <div dir={"rtl"}>
            <List
                sx={{width: '100%', maxWidth: 360}}
                component="nav"
                aria-labelledby="nested-list-subheader"
                subheader={
                    <Stack direction={"row"} spacing={3} sx={{
                        marginRight:2,
                        display:"flex",
                        alignItems:"right",
                        marginBottom:3
                    }}>
                    </Stack>

                }>
                <ListItemButton component={Link} to={"/"}>
                    <ListItemIcon>
                        <DashboardIcon sx={{fontSize: "32px", color: "white"}}/>
                    </ListItemIcon>
                    <ListItemText
                        component={Link} to={`/`}
                        sx={{textAlign: "right", color: "white"}}
                        primary="داشبورد"/>
                </ListItemButton>
                <ListItemButton component={Link} to={"/createfactor"}>
                    <ListItemIcon>
                        <PostAddIcon sx={{fontSize: "32px", color: "white"}}/>
                    </ListItemIcon>
                    <ListItemText
                        sx={{textAlign: "right", color: "white"}}
                        primary="ثبت فاکتور جدید"/>
                </ListItemButton>
                {role === "admin" ?
                    <ListItemButton component={Link} to={"/mngcustomer"}>
                        <ListItemIcon>
                            <PermContactCalendarIcon sx={{fontSize: "32px", color: "white"}}/>
                        </ListItemIcon>
                        <ListItemText
                            sx={{textAlign: "right", color: "white"}}
                            primary="مدیریت مشتری ها"/>
                    </ListItemButton>
                    : ""}
                {role === "admin" ?
                    <ListItemButton component={Link} to={"/addcustomer"}>
                        <ListItemIcon>
                            <PersonAddAltIcon sx={{fontSize: "32px", color: "white"}}/>
                        </ListItemIcon>
                        <ListItemText
                            sx={{textAlign: "right", color: "white"}}
                            primary="افزودن مشتری"/>
                    </ListItemButton>
                    : ""}
                {role === "admin" ?
                    <ListItemButton sx={{fontSize:"12px"}} component={Link} to={"/mngproduct"}>
                        <ListItemIcon>
                            <CategoryIcon sx={{fontSize: "32px", color: "white"}}/>
                        </ListItemIcon>
                        <ListItemText
                            sx={{textAlign: "right", color: "white"}}
                            primary="مدیریت کالاها"/>
                    </ListItemButton>
                    : ""}
                {role === "admin" ?
                    <ListItemButton sx={{fontSize:"12px"}} component={Link} to={"/addproduct"}>
                        <ListItemIcon>
                            <AddShoppingCartIcon sx={{fontSize: "32px", color: "white"}}/>
                        </ListItemIcon>
                        <ListItemText
                            sx={{textAlign: "right", color: "white"}}
                            primary="افزودن کالا جدید"/>
                    </ListItemButton>
                    : ""}
                {role === "admin" ?
                    <ListItemButton sx={{fontSize:"12px"}} component={Link} to={"/mnguser"}>
                        <ListItemIcon>
                            <ManageAccountsIcon sx={{fontSize: "32px", color: "white"}}/>
                        </ListItemIcon>
                        <ListItemText
                            sx={{textAlign: "right", color: "white"}}
                            primary="مدیریت کاربران"/>
                    </ListItemButton>
                    : ""}
                {role === "admin" ?
                    <ListItemButton sx={{fontSize:"12px"}} component={Link} to={"/adduser"}>
                        <ListItemIcon>
                            <PersonAddAltIcon sx={{fontSize: "32px", color: "white"}}/>
                        </ListItemIcon>
                        <ListItemText
                            sx={{textAlign: "right", color: "white"}}
                            primary="ایجاد کاربر جدید"/>
                    </ListItemButton>
                    : ""}
                {role === "admin" ?
                    <ListItemButton sx={{fontSize:"12px"}} component={Link} to={"/report"}>
                        <ListItemIcon>
                            <AssessmentIcon sx={{fontSize: "32px", color: "white"}}/>
                        </ListItemIcon>
                        <ListItemText
                            sx={{textAlign: "right", color: "white"}}
                            primary="گزارشات"/>
                    </ListItemButton>
                    : ""}
            </List>
        </div>
    )
}
export default MenuList