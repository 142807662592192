import React,{useState,useEffect} from "react";
import axios from "axios";
import {Navigate} from "react-router-dom";
import ErrorMsg from "./msg/ErrorMsg";
import SuccsMsg from "./msg/SuccsMsg";
import {
    Box, CircularProgress, Dialog, Grid, IconButton, styled, Table, TableBody, TableCell, tableCellClasses,
    TableContainer, TableHead, TableRow
} from "@mui/material";
import Appbar from "./Appbar";
import MenuList from "./MenuList";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteIcon from "@mui/icons-material/Delete";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));
const ManageCustomer=()=>{
    const [loading , setLoading] = useState(true);
    const [customerList , setCustomerList] = useState([]);
    const [errorMsg, setErrorMsg] = useState("");
    const [succsMsg, setSuccsMsg] = useState("");
    const [update ,setUpdate] = useState(false);
    useEffect(() => {
        const data = {
            token : localStorage.getItem("adminlogintoken"),
        }
        axios.post("https://o.tinpod.ir/api/get_customerlist.php",data)
            .then(response => {
                setLoading(false);
                if (response.status === 200) {
                    if (response.data.error === false)
                        setCustomerList(response.data.data)
                    else{
                        localStorage.clear();
                    }
                }
            })
            .catch(error => {
                setLoading(false);
            })
        setUpdate(false);
    }, [update])
    const Redirect = () => {
        return (<Navigate replace to="/login"/>)
    }
    const handle_btn_deleteclick=(id)=>{
        setLoading(true)
        const data={
            token : localStorage.getItem("adminlogintoken"),
            customerid : id,
        }
        axios.post("https://o.tinpod.ir/api/deletecustomer.php",data)
            .then(response => {
                setLoading(false);
                if (response.status === 200) {
                    if (response.data.error === false) {
                        setSuccsMsg(response.data.message);
                        setUpdate(true);
                    }
                    else{
                        //localStorage.clear();
                    }
                }
            })
            .catch(error => {
                setLoading(false);
            })
    }
    return (
        <div>
            {localStorage.getItem("adminlogintoken") === "" ? <Redirect/> : ''}
            {localStorage.getItem("adminlogintoken") === null ? <Redirect/> : ''}
            {errorMsg !== '' ? <ErrorMsg message={errorMsg} openn={true} seterrormsg={setErrorMsg}/> : ''}
            {succsMsg !== '' ? <SuccsMsg message={succsMsg} openn={true} setsuccsmsg={setSuccsMsg}/> : ''}
            <Grid container spacing={2} sx={{marginTop: 0}}>
                <Grid item xs={12} md={10} sx={{backgroundColor: "#ffffff", minHeight: "100vh"}}>
                    <Appbar/>
                    <Box sx={{padding:2}} dir={"rtl"}>
                        <TableContainer >
                            <Table
                                sx={{ minWidth: 250,maxHeight:'80%',overflowY:'auto'}}>
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell align={"right"}>
                                            {"نام مشتری"}
                                        </StyledTableCell>
                                        <StyledTableCell align={"right"}>
                                            {"شماره تماس"}
                                        </StyledTableCell>
                                        <StyledTableCell align={"left"}>
                                            {"عملیات"}
                                        </StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                {customerList !== "" ?
                                    <TableBody>
                                        {customerList.map(list => (
                                            <StyledTableRow key={list.id}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                <StyledTableCell align={"right"}>
                                                    {list.name}
                                                </StyledTableCell >
                                                <StyledTableCell component="th" scope="row" align={"right"}>
                                                    {list.phone}
                                                </StyledTableCell>
                                                <StyledTableCell component="th" scope="row" align={"left"}>
                                                    <IconButton
                                                        onClick={()=>{
                                                            handle_btn_deleteclick(list.id)
                                                        }}
                                                        aria-label="delete">
                                                        <DeleteIcon sx={{color:"red"}} />
                                                    </IconButton>
                                                </StyledTableCell>
                                            </StyledTableRow >
                                        ))}
                                    </TableBody>
                                    : '' }
                            </Table>
                        </TableContainer>
                    </Box>
                </Grid>
                <Grid item xs={0} md={2} sx={{
                    backgroundColor: '#2b2b2b',
                    minHeight: "100vh",
                    display: {xs: "none", md: "block"}
                }}>
                    <MenuList/>
                </Grid>
            </Grid>
            <Dialog
                open={loading}
                scroll="paper">
                <CircularProgress sx={{margin: 2}}/>
            </Dialog>
        </div>
    )
}
export default ManageCustomer;